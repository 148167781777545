import React from 'react';
import { Link } from 'gatsby';

const AmortisationFAQ = () => ([
    {
      title: 'Amortization meaning?',
      description: (
        <>
          Amortization, or amortization expense, refers to gradually paying off the mortgage balance over time through regular smaller payments.<br /><br />
          As time goes on, the loan decreases and the more you own of the tangible assets or intangible assets.
        </>
      ),
    },
    {
      title: 'What is the benefit of amortisation?',
      description: (
        <>
          The benefit of a loan amortisation schedule is that it allows homeownership to be more affordable.
          <br/>
          <br/>
          The structure repayment plan breaks down the cost into smaller monthly payments, and depending on the mortgage terms agreed, allows you to accurately budget your cash flow for the duration of the term.
          <br/>
          <br/>
          As you make the payments, the homeownership and your equity in the residual value will grow so you build a tangible asset that can increase in value.
        </>
      ),
    },
    {
      title: 'What is the difference between principal and interest in amortisation?',
      description: (
        <>
          The monthly loan payment is broken down by the principal and the interest owed. The principal refers to the original amount you borrow for the mortgage. The interest is what is charged by the lender for lending you the money for the mortgage on top of the principal.
          <br/>
          <br/>
          There are some mortgage terms that are interest-only, which means that with each monthly payment, you are only paying the minimum amount, which is the interest owed to the lender. This means you won't be reducing the initial value of the mortgage principal with each payment.
          <br/>
          <br/>
          When you make mortgage overpayments, you are usually directly paying the principal amount, which is why this is a good option if you want to become mortgage-free quicker and have a smaller ending loan balance.
        </>
      ),
    },
    {
      title: 'How does the amortisation schedule change over time?',
      description: (
        <>
         Amortization expenses are designed with the payments at the start of the mortgage being front-loaded with the interest payments and gradually decrease over time when you start to pay more towards the principal later on in the mortgage.
         <br/>
         <br/>
         This helps borrowers manage higher interest costs in the early years and accelerates debt reduction as the loan term goes on.
         <br/>
         <br/>
         To calculate loan amortization, it's best to speak to adviser to get an accurate breakdown.
        </>
      ),
    },
    {
      title: 'Can I make overpayments on my mortgage?',
      description: (
        <>
          Most mortgages allow you to make additional payments on your mortgage, usually up to 10% of the annual mortgage balance.
          <br/>
          <br/>
          Making overpayments on your mortgage can help you pay off the mortgage faster and save on the amount of interest you pay overall.
          <br/>
          <br/>
          However, most lenders will charge an early repayment charge (ERC) if you go over your overpayments threshold, so it's important to check the terms in your mortgage agreement.
        </>
      ),
    },
  ]);

  export default AmortisationFAQ;
